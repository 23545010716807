import React, { useState } from 'react';
import { Grid, Typography, Link  } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';

import { useTheme } from '@emotion/react';

export default function DropdownInfo (props) {
    const theme = useTheme();
    const [openContactAccess, setOpenContactAccess] = useState(false);
    const [openLinkFoot, setOpenLinkFoot] = useState(false);
    const [openLinkHand, setOpenLinkHand] = useState(false);

    const styles = {
        container: {
         backgroundColor: "black",
         padding: "1rem 0"
        },
        navItem: {
            cursor: "pointer",
            marginTop: "0.5rem",
            borderBottom: "solid 1px white",
        },
        navSubItem: {
            cursor: "pointer",
            marginTop: "0.5rem",
            borderBottom: "solid 1px rgba(255,255,255,0.6)",
        },
        linkItem: {
            textDecoration: "none!important",
            color: "white",
            paddingLeft: "3rem!important",
            '&:hover': {
                color: theme.palette.colors.green + "!important",
            },
        },
        linkSubItem: {
            width: "100%",
            display: "block",
            textDecoration: "none!important",
            color: "white",
            paddingLeft: "3rem!important",
            '&:hover': {
                color: theme.palette.colors.green + "!important",
            },
        }
    };

    return (
        <Grid container item justifyContent={"center"} xs={6} md={8} sx={styles.container} onMouseEnter={props.showDropdown} onMouseLeave={props.hideDropdown}>
            <Grid container item direction={"column"} sx={styles.column} xs={5}>
                <Grid item sx={styles.navItem} onMouseEnter={() => {setOpenContactAccess(true)}} onMouseLeave={() => setOpenContactAccess(false)}>
                    <Typography className={openContactAccess ? "nav-item-active" : ""} sx={styles.linkItem} variant={"h5"}>Contacts & accès</Typography>
                </Grid>

                <Grid item sx={styles.navItem} onMouseEnter={() => setOpenLinkFoot(true)} onMouseLeave={() => setOpenLinkFoot(false)}>
                    <Typography className={openLinkFoot ? "nav-item-active" : ""} sx={styles.linkItem} variant={"h5"}>Liens utiles football</Typography>
                </Grid>

                <Grid item sx={styles.navItem} onMouseEnter={() => setOpenLinkHand(true)} onMouseLeave={() => setOpenLinkHand(false)}>
                    <Typography className={openLinkHand ? "nav-item-active" : ""} sx={styles.linkItem} variant={"h5"}>Liens utiles handball</Typography>
                </Grid>

                <Grid item sx={styles.navItem}>
                    {props.buildSubLink("Nos partenaires", "/partenaires", false)}
                </Grid>
            </Grid>
            <Grid container item direction={"column"} xs={5}>
            {openContactAccess && 
                <Grid container item direction={"column"} xs>
                    <Grid container item sx={{padding: "10px 0 0 10px"}} onMouseEnter={() => setOpenContactAccess(true)} onMouseLeave={() => setOpenContactAccess(false)}>
                        <Grid item sx={styles.navSubItem} xs>
                            {props.buildSubLink("Contacts", "/contacts", true)}
                        </Grid>
                    </Grid>

                    <Grid container item sx={{padding: "0 10px"}} onMouseEnter={() => setOpenContactAccess(true)} onMouseLeave={() => setOpenContactAccess(false)}>
                        <Grid item sx={styles.navSubItem} xs>
                            {props.buildSubLink("Accès", "/acces", true)}
                        </Grid>
                    </Grid>
                </Grid>
                }

                {openLinkFoot && 
                <Grid container item direction={"column"} xs>
                    <Grid container item sx={{padding: "10px 0 0 10px"}} onMouseEnter={() => setOpenLinkFoot(true)} onMouseLeave={() => setOpenLinkFoot(false)}>
                        <Grid item sx={styles.navSubItem} xs>
                            <Link sx={{textDecoration: "none!important"}} href={"https://www.fff.fr/"} target={"_blank"} rel="noreferrer" onClick={() => props.hideDropdown()}>
                                <Typography sx={styles.linkSubItem} variant={"subitem"}>Fédération Française de Football</Typography>
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid container item sx={{padding: "0 10px"}} onMouseEnter={() => setOpenLinkFoot(true)} onMouseLeave={() => setOpenLinkFoot(false)}>
                        <Grid item sx={styles.navSubItem} xs>
                            <Link sx={{textDecoration: "none!important"}} href={"https://footbretagne.fff.fr/"} target={"_blank"} rel="noreferrer" onClick={() => props.hideDropdown()}>
                                <Typography sx={styles.linkSubItem} variant={"subitem"}>Ligue de Bretagne de Football</Typography>
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid container item sx={{padding: "0 10px"}} onMouseEnter={() => setOpenLinkFoot(true)} onMouseLeave={() => setOpenLinkFoot(false)}>
                        <Grid item sx={styles.navSubItem} xs>
                            <Link sx={{textDecoration: "none!important"}} href={"https://foot22.fff.fr/"} target={"_blank"} rel="noreferrer" onClick={() => props.hideDropdown()}>
                                <Typography sx={styles.linkSubItem} variant={"subitem"}>District de Football des Côtes d’Armor</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                }

                {openLinkHand && 
                <Grid container item direction={"column"} xs>
                    <Grid container item sx={{padding: "10px 0 0 10px"}} onMouseEnter={() => setOpenLinkFoot(true)} onMouseLeave={() => setOpenLinkFoot(false)}>
                        <Grid item sx={styles.navSubItem} xs>
                            <Link sx={{textDecoration: "none!important"}} href={"http://www.ff-handball.org/"} target={"_blank"} rel="noreferrer" onClick={() => props.hideDropdown()}>
                                <Typography sx={styles.linkSubItem} variant={"subitem"}>Fédération Française de Handball</Typography>
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid container item sx={{padding: "0 10px"}} onMouseEnter={() => setOpenLinkFoot(true)} onMouseLeave={() => setOpenLinkFoot(false)}>
                        <Grid item sx={styles.navSubItem} xs>
                            <Link sx={{textDecoration: "none!important"}} href={"http://www.lbhb.com/"} target={"_blank"} rel="noreferrer" onClick={() => props.hideDropdown()}>
                                <Typography sx={styles.linkSubItem} variant={"subitem"}>Ligue de Bretagne de Handball</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </Grid>
        </Grid>
    )
}