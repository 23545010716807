import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Popover, Link, Badge } from "@mui/material";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import '../../assets/Navigation.scss'
import '../../assets/Hamburger.scss';

import DropdownEquipe from "./Dropdown/DropdownEquipe";
import DropdownMedia from "./Dropdown/DropdownMedia";
import DropdownInfo from "./Dropdown/DropdownInfo";
import {useTheme} from "@emotion/react";
import ApiClient from "../../service/ApiClient";
import DropdownClub from './Dropdown/DropdownClub';

import moment from "moment";

export default function Navigation (props) {
    const theme = useTheme();
    const location = useLocation()
    const logoSrc = process.env.PUBLIC_URL + "/images/USMB-logo.png";
    const [anchorProfile, setAnchorProfile] = useState(null);
    const [openClub, setOpenClub] = useState(false);
    const [openTeam, setOpenTeam] = useState(false);
    const [openMedia, setOpenMedia] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [categories, setCategories] = useState(null);

    const styles = {
        navTop: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: 700,
            color: 'white',
            zIndex: 11,
            height: "100%",
            [theme.breakpoints.up('md')]: {
                height: "50%",
            },
        },
        iconSmall: {
            textDecoration: "none!important",
            color: "white",
            zIndex: 11,
            '&:hover': {
                color: "white",
                "& .MuiBadge-badge": {
                    color: "white!important",
                }
            },
        },
        buttonMenu: {
           // padding: '0 1rem',
            cursor: "pointer",
            height: "100%",
            borderColor: theme.palette.colors.green,
          '&:hover': {
              borderBottom: "5px solid #048f00",
              '& $navItem': {
                  color: theme.palette.colors.green + "!important",
              }
          }
        },
        navItem: {
            width: 'auto',
            color: "white!important",
            textDecoration: "none!important",
            '&:hover': {
                color: theme.palette.colors.green,
            }
        },
        linkSubItem: {
            width: "100%",
            display: "block",
            textDecoration: "none!important",
            color: "white",
            paddingLeft: "3rem!important",
            '&:hover': {
                color: theme.palette.colors.green + "!important",
            },
        },
        cartIcon: {
            textDecoration: "none!important",
            color: "white",
            '&:hover': {
                color: "black",
                "& .MuiBadge-badge": {
                    color: "white!important",
                }
            },
        }
    };

    useEffect(() => {
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, [])


    async function loadData() {
        try {
            let result = await ApiClient.instance.getCategory();
            setCategories(result.data);
        } catch (error) {
            console.log(error);
        }
    }

    function buildLink(title, path) {
        if (props.isShop) {
            return (
                <Link sx={styles.navItem} href={(process.env.REACT_APP_ENV === "dev" ? "http" : "https") + "://" + process.env.REACT_APP_DOMAIN + path} state={{prevUrl: location.pathname}}>
                    <Typography variant={"h5"}>{title}</Typography>
                </Link>
            );
        } else {
            return (
                <Link sx={styles.navItem} component={RouterLink} to={path} state={{prevUrl: location.pathname}}>
                    <Typography variant={"h5"}>{title}</Typography>
                </Link>
            );
        }
    }

    function buildSubLink(title, path, isSub, isExternalLink=false) {
        if (props.isShop) {
            return (
                <Link sx={styles.navItem} href={(process.env.REACT_APP_ENV === "dev" ? "http" : "https") + "://" + process.env.REACT_APP_DOMAIN + path} state={{prevUrl: location.pathname}}>
                    <Typography target={isExternalLink ? "_blank" : "_self"} sx={styles.linkSubItem} variant={isSub ? "subitem" : "h5"}>{title}</Typography>
                </Link>
            );
        } else {
            return (
                <Link sx={styles.navItem} component={RouterLink} to={path} state={{prevUrl: location.pathname}}>
                    <Typography sx={styles.linkSubItem} variant={isSub ? "subitem" : "h5"}>{title}</Typography>
                </Link>
            );
        }
    }

    function buildShopLink(title, path) {
        if (!props.isShop) {
            return (
                <Link sx={styles.navItem} href={(process.env.REACT_APP_ENV === "dev" ? "http" : "https") + "://boutique." + process.env.REACT_APP_DOMAIN + path} state={{prevUrl: location.pathname}}>
                    <Typography variant={"h5"}>{title}</Typography>
                </Link>
            );
        } else {
            return (
                <Link sx={styles.navItem} component={RouterLink} to={path} state={{prevUrl: location.pathname}}>
                    <Typography variant={"h5"}>{title}</Typography>
                </Link>
            );
        }
    }

    function buildLargeContent() {
        return (
            <Grid className={"h-100"} container md={8} sm={6} xs={7} justifyContent={"flex-start"} alignItems={"center"} direction={"row"} item>
            {window.innerWidth <= 900 &&
            <Grid item>
                <Box className={"hamburger hamburger--slider" + (props.menuActive ? " is-active" : "")} alignItems={"center"} display={"flex"}
                     onClick={() => props.handleChangeActiveMenu("menu")}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"/>
                            </span>
                </Box>
            </Grid>
            }
            <Grid item>
                <Link to={"/"} component={RouterLink} state={{prevUrl: location.pathname}}>
                    <img className={"nav-logo ml-3"} alt={"usmb-logo"} src={logoSrc}/>
                </Link>
               
            </Grid>
            <Grid className={"h-100"} container direction={"column"} item md sm xs>
                <Grid sx={styles.navTop} item container alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                    {window.innerWidth > 900 &&
                        <Grid item>
                            <Typography variant={"h4"} sx={styles.navTop}>US Méné Bré</Typography>
                        </Grid>
                    }

                    <Grid className={"h-100"} container item xs={2} md={5} lg={4} xl={3} justifyContent={"flex-end"} alignItems={"center"}>
                        { props.user === null && props.isAuth !== null && window.innerWidth >= 900 &&
                        <Grid item sx={{height: "100%"}}>
                            <Link className={"login-nav"} component={RouterLink} to={"/connexion"} state={{prevUrl: location.pathname}}>
                                <Box className={"login-button"} display={"flex"} alignItems={"center"} height={1}>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <AccountCircleIcon/>
                                        <span className={"ml-2"}>Connexion</span>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                        }

                        { props.user !== null && window.innerWidth >= 900 &&
                        <Grid className={"h-100"} item style={{cursor: "pointer"}}>
                            <Box display={"flex"} alignItems={"center"} height={1}>
                                <Box onClick={(event) => {
                                    setAnchorProfile(event.currentTarget);
                                    setOpenProfile(!openProfile);
                                }} display={"flex"} alignItems={"center"}>
                                     <AccountCircleIcon/>
                                    <span className={"ml-2"}>{props.user.Pseudo}</span>
                                </Box>
                            </Box>
                        </Grid>}

                        { props.isShop &&
                            <Grid item sx={{marginLeft: "1rem"}}>
                                <Link sx={styles.cartIcon} component={RouterLink} to={"/panier"} state={{prevUrl: location.pathname}}>
                                    <Badge badgeContent={props.itemCartNumber} sx={{"& .MuiBadge-badge": {backgroundColor: "black"}}}>
                                        <ShoppingBagIcon/>
                                    </Badge>
                                </Link>
                            </Grid>
                        }
                    </Grid>
                    <Popover
                        open={openProfile}
                        anchorEl={anchorProfile}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        onClose={() => {
                            setOpenProfile(false);
                        }}
                    >
                        <Link className={"item-menu"} component={RouterLink} to="/mon-compte" state={{prevUrl: location.pathname}}>
                            <Box className={"p-2 pl-4 pr-4 item-menu"} alignItems={"center"} display={"flex"} color={"black"} onClick={(event) => {
                                    setAnchorProfile(event.currentTarget);
                                    setOpenProfile(!openProfile);
                                }}>
                                <i className="material-icons">person</i>
                                <Typography className={"ml-2"}>Mon compte</Typography>
                            </Box>
                        </Link>

                        {props.isAdmin &&
                        <Link className={"item-menu"} component={RouterLink} to={"/categories"} state={{prevUrl: location.pathname}}>
                            <Box className={"p-2 pl-4 pr-4 item-menu"} alignItems={"center"} display={"flex"}
                                 color={"black"} onClick={(event) => {
                                    setAnchorProfile(event.currentTarget);
                                    setOpenProfile(!openProfile);
                                }}>
                                <i className="material-icons">article</i>
                                <Typography className={"ml-2"}>Categories</Typography>
                            </Box>
                        </Link>
                        }

                        {props.isAdmin &&
                        <Link className={"item-menu"} component={RouterLink} to="/equipes" state={{prevUrl: location.pathname}}>
                            <Box className={"p-2 pl-4 pr-4 item-menu"} alignItems={"center"} display={"flex"}
                                 color={"black"} onClick={(event) => {
                                    setAnchorProfile(event.currentTarget);
                                    setOpenProfile(!openProfile);
                                }}>

                                <i className="material-icons">group</i>
                                <Typography className={"ml-2"}>Equipes</Typography>
                            </Box>
                        </Link>
                        }

                        {props.isAdmin &&
                        <Link className={"item-menu"} component={RouterLink} to="/saisons" state={{prevUrl: location.pathname}}>
                            <Box className={"p-2 pl-4 pr-4 item-menu"} alignItems={"center"} display={"flex"}
                                 color={"black"} onClick={(event) => {
                                    setAnchorProfile(event.currentTarget);
                                    setOpenProfile(!openProfile);
                                }}>

                                <i className="material-icons">calendar_today</i>
                                <Typography className={"ml-2"}>Saisons</Typography>
                            </Box>
                        </Link>
                        }

                        {props.isAdmin &&
                        <Link className={"item-menu"} component={RouterLink} to="/evenements" state={{prevUrl: location.pathname}}>
                            <Box className={"p-2 pl-4 pr-4 item-menu"} alignItems={"center"} display={"flex"} color={"black"} onClick={(event) => {
                                    setAnchorProfile(event.currentTarget);
                                    setOpenProfile(!openProfile);
                                }}>

                                <i className="material-icons">event</i>
                                <Typography className={"ml-2"}>Evenements</Typography>
                            </Box>
                        </Link>
                        }

                        {(props.isAdmin || props.isAdminShop) &&
                        <Link className={"item-menu"} component={RouterLink} to={"/boutique-commande"} state={{prevUrl: location.pathname}}>
                            <Box className={"p-2 pl-4 pr-4 item-menu"} alignItems={"center"} display={"flex"}
                                 color={"black"} onClick={(event) => {
                                    setAnchorProfile(event.currentTarget);
                                    setOpenProfile(!openProfile);
                                }}>
                                <i className="material-icons">store</i>
                                <Typography className={"ml-2"}>Gestion commande</Typography>
                            </Box>
                        </Link>
                        }

                        <Box  className={"p-2 pl-4 pr-4 item-menu"} style={{cursor: "pointer"}} alignItems={"center"} display={"flex"} borderTop={"solid 1px #f0ecec"} onClick={() => props.signOut()}>
                            <i className="material-icons">power_settings_new</i>
                            <Typography className={"ml-2"}>Déconnexion</Typography>
                        </Box>
                    </Popover>
                </Grid>

                { window.innerWidth > 900 &&
                    <Grid className={"h-50"} item container alignItems={"center"} justifyContent={"flex-start"}>
                        <Box sx={styles.buttonMenu} alignItems={"center"} display={"flex"}
                            onMouseOver={(event) => setOpenClub(true)}
                            onMouseLeave={() => setOpenClub(false)}>
                            <Box sx={styles.navItem}>
                                <Typography variant={"h5"}>Le Club</Typography>
                            </Box>
                        </Box>

                        <Box sx={styles.buttonMenu} alignItems={"center"} display={"flex"}
                             onMouseOver={(event) => setOpenTeam(true)}
                             onMouseLeave={() => setOpenTeam(false)}>
                            <Box sx={styles.navItem}>
                                <Typography variant={"h5"}>Calendrier / Résultats</Typography>
                            </Box>
                        </Box>

                        {props.isAuth && (props.user.Role === "TESTEUR" || props.user.Role === "ADMINSHOP" || props.isAdmin) &&
                        <Box sx={styles.buttonMenu} alignItems={"center"} display={"flex"}>
                            {buildShopLink("Boutique", "/")}
                        </Box>
                        }

                        <Box sx={styles.buttonMenu} alignItems={"center"} display={"flex"}
                             onMouseOver={(event) => setOpenMedia(true)}
                             onMouseLeave={() => setOpenMedia(false)}>
                            <Box sx={styles.navItem}>
                                <Typography variant={"h5"}>Médias</Typography>
                            </Box>
                        </Box>

                        <Box sx={styles.buttonMenu} alignItems={"center"} display={"flex"}
                             onMouseOver={(event) => setOpenInfo(true)}
                             onMouseLeave={() => setOpenInfo(false)}>
                            <Box sx={styles.navItem}>
                                <Typography variant={"h5"}>Infos Pratiques</Typography>
                            </Box>
                        </Box>

                        <Box sx={styles.buttonMenu} alignItems={"center"} display={"flex"}>
                            {buildLink("Jeu Pronos", "/monProno")}
                        </Box>
                    </Grid>
                }
            </Grid>
        </Grid>
        );
    }

    function buildSmallContent() {
        return (
            <Grid className={"h-100"} container md={8} sm={6} xs={7} justifyContent={"space-between"} alignItems={"center"} direction={"row"} item>
                <Grid item xs={1}>
                    <Box className={"hamburger hamburger--slider" + (props.menuActive ? " is-active" : "")} alignItems={"center"} display={"flex"}
                        onClick={() => props.handleChangeActiveMenu("menu")}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"/>
                            </span>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Link to={"/"} component={RouterLink} state={{prevUrl: location.pathname}}>
                        <img className={"nav-logo"} alt={"usmb-logo"} src={logoSrc}/>
                    </Link>
               </Grid>

               <Grid container item xs={3} sm={2} justifyContent={"space-between"} alignItems={"center"}>
               { props.user === null &&
                    <Grid item>
                        <Link sx={styles.iconSmall} component={RouterLink} to={"/connexion"} state={{prevUrl: location.pathname}}>
                            <Badge badgeContent={0}>
                                <AccountCircleIcon/>
                            </Badge>
                        </Link>
                    </Grid>
                }

                { props.user !== null &&
                    <Grid item>
                         <Link sx={styles.iconSmall} component={RouterLink} to={"/mon-compte"} state={{prevUrl: location.pathname}}>
                            <Badge badgeContent={0}>
                                <AccountCircleIcon/>
                            </Badge>
                         </Link>
                    </Grid>
                }

                { props.isShop &&
                    <Grid item>
                        <Link sx={styles.iconSmall} component={RouterLink} to={"/panier"} state={{prevUrl: location.pathname}}>
                            <Badge badgeContent={props.itemCartNumber} sx={{"& .MuiBadge-badge": {backgroundColor: "black"}}}>
                                <ShoppingBagIcon/>
                            </Badge>
                        </Link>
                    </Grid>
                }
               </Grid>
            </Grid>
        );
    }

    return (
        <Box height={1} position={"relative"}>
            <Box className={"green-band"} sx={{backgroundColor: theme.palette.colors.green}}/>
            <Box height={1}>
                <Grid className={"h-100"} container justifyContent={"space-evenly"} alignItems={"center"}>
                    <Grid item>
                        <Box className={(props.dimensions.width > 900) ? "white-band-left md" : "white-band-left sm"} sx={{backgroundColor: theme.palette.colors.white}}/>
                    </Grid>
                    { window.innerWidth > 900 &&
                        buildLargeContent()
                    }

                    { window.innerWidth <= 900 &&
                        buildSmallContent()
                    }
                    <Grid item>
                        <Box className={(props.dimensions.width > 900) ? "white-band-right md" : "white-band-right sm"} sx={{backgroundColor: theme.palette.colors.white}}/>
                    </Grid>
                </Grid>
            </Box>

            {openClub &&
            <Grid container justifyContent={"center"}>
                <DropdownClub hideDropdown={() => setOpenClub(false)} showDropdown={() => setOpenClub(true)} buildLink={buildLink} buildSubLink={buildSubLink}/>
            </Grid>
            }

            {openTeam &&
            <Grid container justifyContent={"center"}>
                <DropdownEquipe hideDropdown={() => setOpenTeam(false)} showDropdown={() => setOpenTeam(true)} categories={categories} buildLink={buildLink} buildSubLink={buildSubLink}/>
            </Grid>
            }

            {openMedia &&
            <Grid container justifyContent={"center"}>
                <DropdownMedia hideDropdown={() => setOpenMedia(false)} showDropdown={() => setOpenMedia(true)} buildLink={buildLink} buildSubLink={buildSubLink}/>
            </Grid>
            }

            { openInfo &&
            <Grid container justifyContent={"center"}>
                <DropdownInfo hideDropdown={() => setOpenInfo(false)} showDropdown={() => setOpenInfo(true)} buildLink={buildLink} buildSubLink={buildSubLink}/>
            </Grid>
            }
        </Box>
    )
}